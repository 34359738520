import React, { useState , useEffect } from 'react';
import '../assets/styles/ResponsiveContainer.css';
import '../assets/styles/LoginForm.css';
import { getIntroData, postIntroData } from '../logic/localAdandApp';
import Toast from './Toast';

const AdsAndApp = ({ message , isSuccess}) => {
    const [token, setToken] = useState('');
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
        setToken(storedToken);
    }
    getIntroData(storedToken)
    .then((response) => {
        setFormData(response.data);
    })
    .catch(error => {
    console.error('Error:', error);
    });
    
    }, []);

    const handleChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSubmit = async ()  => {
        console.log(formData);
        await postIntroData(formData,token,setError,setSuccess);
    };
    const inlineStyles ={
        form:{
            zIndex:-1
        }
      }
    return (
        <div className="responsive-container">
            {error && <div><Toast message={error} isSuccess={false}/></div>}
          {success && <div><Toast message={success} isSuccess={true} /></div>}
            <div className="data-post-container">
                
                <div className="switch-container">
                <span className="switch-label">{formData.localads ? 'LocaAd On' : 'LocalAd Off'}</span>
                
                    <label className="switch">
                        <input
                        type="checkbox"
                        name="switch"
                        checked={formData.localads}
                        onChange={(e) => handleChange('localads', e.target.checked)}
                        />
                        <span className="slider round"></span>
                    </label>
                    
                </div>
                <br />
                <label>
                    Ads Gif Url:
                    <input
                    type="url"
                    name="text"
                    value={formData.localads_img}
                    onChange={(e) => handleChange('localads_img', e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Ads Url:
                    <input
                    type="url"
                    name="url"
                    value={formData.localads_url}
                    onChange={(e) => handleChange('localads_url', e.target.value)}
                    />
                </label>
                <br />
                <br />
                <button onClick={handleSubmit}>Ok</button>
                
              


                <form style={inlineStyles.form}>
                    <div style={inlineStyles.form} className="form-group">
                        <label style={inlineStyles.form} htmlFor="data">Enter Data</label>
                        <textarea style={inlineStyles.form}
                        rows={20}
                        cols={40}
                        ></textarea>
                    </div>
                </form> 
            </div>
        </div>
      );
};

export default AdsAndApp;