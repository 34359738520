import React, { useState , useEffect } from 'react';
import '../assets/styles/ResponsiveContainer.css';
import { switchGetData, switchPostData } from '../logic/switchPostData';
import saveToHistory from '../logic/saveToHistory';
import '../assets/styles/DataPostForm.css';
import Modal from 'react-modal';
import fetchData from '../logic/fetchLiveData';
import postData from '../logic/postLiveData';
import saveThreeDee from '../logic/saveThreeDee';
import Toast from './Toast';

function HomePage() {
    const [token, setToken] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    const [threed, setThreeD] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dots, setDots] = useState('');
    const [historySaving, setHistorySaving] = useState(false);
    const [threedSaving, setThreedSaving] = useState(false);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          setToken(storedToken);
          
        }
        switchGetData(storedToken)
          .then(data => {
            setIsChecked(data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
        fetchData(storedToken)
        .then(data => {
          
          setData(data);
        })
        .catch(error => {
          // Handle the error
          console.error('Error:', error);
        })
        .finally(() => {
          setLoading(false)
        });

        const intervalId = setInterval(() => {
          setDots((prevDots) => (prevDots.length >= 4 ? '' : prevDots + '.'));
        }, 110); // Adjust the interval as needed

        return () => {
          clearInterval(intervalId);
        };
    }, []);

    const handleSwitchToggle = async() => {
        // Toggle the switch locally
        setIsChecked(!isChecked);
        await switchPostData(isChecked,token,setError,setSuccess)
    };
    const savePostHistory = async () =>{
        const liveData = localStorage.getItem('liveData');
        await saveToHistory(liveData,token,setError,setSuccess,setHistorySaving)
    }

    const handleConfirm = () => {
      // Perform the action, e.g., post data
      savePostLiveData();
      
      // Close the modal
      setIsModalOpen(false);
    };
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
  
    const savePostLiveData = async () =>{
      await postData(data, token, setError, setSuccess);
    }
  
    const savePostThreeDee = async () =>{
      await saveThreeDee(threed,token,setError,setSuccess,setThreedSaving);
    }
  
    const handleTextareaChange = (e) => {
      const v = e.target.value.trim();
      setData(v)
      localStorage.setItem('liveData',v);
    };
    const cleanAndFormat =()=>{
        let splitDataLine = data.split('\n');
        let splitDataRow3 = splitDataLine[2].split(',');
        let mainNum = splitDataRow3[1];
  
        const cleanData = `enabled,0
title,2d,modern,internet,key
12:00 PM,${mainNum}
12:01 PM,--,0,0,--
4:30 PM,--,0,0,--
9:30 AM,--,--,--,--
2:00 PM,--,--,--,--`;
        setData(cleanData);
    }
  
    const unCleanData = () =>{
      const originalData = localStorage.getItem('data');
      setData(originalData);
    }
  
    const inlineStyles ={
      input:{
        marginRight: '10px', // Add some margin between input and button
        padding: '6px',
        width: '100px',
      },
      threeButton:{
        backgroundColor: 'green',
        color: 'white',
        padding: '8px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop:'5px'
      },
      container: {
        display: 'flex',
        alignItems: 'center', // Align items vertically in the center
      },
  
    }
  
    return (
        <div className="responsive-container">
          {error && <div><Toast message={error} isSuccess={false}/></div>}
          {success && <div><Toast message={success} isSuccess={true} /></div>}
          <div className="data-post-container">
            <div className="button-group">
            <button className="logout-button" onClick={savePostHistory}>
              {historySaving ? "Saving......" : "Save History"}
            </button>
            <div className="switch-container">
              <label className="switch-label">Own</label>
              <label className="switch">
                <input type="checkbox" checked={isChecked} onChange={handleSwitchToggle} />
                <span className="slider"></span>
              </label>
            </div>
          </div>
            <form>
              <div className="form-group">
                <label htmlFor="data">Enter Data</label>
                  <textarea
                    rows={8}
                    cols={40}
                    id="data"
                    value={loading ? `${dots}`:data}
                    disabled={loading}
                    onChange={(e) => 
                      handleTextareaChange(e)
                    }
                  ></textarea>
                  
                </div>
              <button type="button" onClick={handleOpenModal}>Post Data</button>
              <button type="button" onClick={cleanAndFormat}>Clean</button>
              <button type="button" onClick={unCleanData}>UnClean</button>
              
            </form>
            <div style={inlineStyles.container}>
              <input 
              style={inlineStyles.input} 
              placeholder='000'
              onChange={(e) => 
                    setThreeD(e.target.value)
                  }
              ></input>
              <button type="button" style={inlineStyles.threeButton} onClick={savePostThreeDee}>
                {threedSaving? "Saving....." : "Save 3D"}
              </button>
            </div>
            
            <Modal
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              contentLabel="Confirm Box"
              ariaHideApp={false}
              className="confirm-modal"
              overlayClassName="confirm-modal-overlay"
            >
              <div className="modal-content">
                <h2>ဂဏန်းသေချာစစ်ပါ</h2>
                <p>သေချာစစ်ပြီးပြီးလား စစ်ပြီးမှတင်ပါ။?</p>
                <div className="modal-buttons">
                  <button onClick={handleConfirm} className="confirm-btn">
                    Yes
                  </button>
                  <button onClick={handleCloseModal} className="cancel-btn">
                    No
                  </button>
                </div>
              </div>
            </Modal>
          </div>
      </div>
    );
}

export default HomePage;
