import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom';
import React, { useState , useEffect } from 'react';
import Login from './components/Login';
import './assets/styles/ResponsiveContainer.css';
import HomePage from './components/Home';
import Analytics from './components/Analytics';
import AdsAndApp from './components/AdsAndApp';

function App() {
  const [token, setToken] = useState('');
  const [activeComponent, setActiveComponent] = useState('home');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogin = (token) => {
    setToken(token);
  };

  const handleComponentClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'home':
        return <HomePage />;
      case 'analytics':
        return <Analytics />;
      case 'localandappdownload':
        return <AdsAndApp />;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    // Remove token from state and localStorage
    setToken('');
    localStorage.removeItem('token');
  };

  const inlineStyles ={
    button:{
      marginTop: '5px',
    },
  }
  return (
    <div>
      {!token ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div>
          <nav className="navbar">
            <ul className="nav-list">
              <li className={activeComponent === "home" ? "nav-item active" : "nav-item"}>
                <a onClick={() => handleComponentClick('home')}>Home</a>
              </li>
              <li className={activeComponent === "analytics" ? "nav-item active" : "nav-item"}>
                <a onClick={() => handleComponentClick('analytics')}>Analytics</a>
              </li>
              <li className={activeComponent === "localandappdownload" ? "nav-item active" : "nav-item"}>
                <a onClick={() => handleComponentClick('localandappdownload')}>Ads and App</a>
              </li>
              <button className="logout-button" style={inlineStyles.button} onClick={handleLogout}>Logout</button>
              
            </ul>
          </nav>
          {renderComponent()}
        </div>
      )}
    </div>
  );
}

export default App;

