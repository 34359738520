import axios from 'axios';
import { URL } from './consts';

export const fetchDailyUser = async (token) => {
  try {
    const response = await axios.get(`${URL}/connected`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `${token}`,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error loading text file:', error);
    throw error; // Re-throw the error to be caught by the calling function or component
  }
};


export const fetchAllUsers = async (token) => {
  try {
    const response = await axios.get(`${URL}/user-counts`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `${token}`,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error loading text file:', error);
    throw error; // Re-throw the error to be caught by the calling function or component
  }
};

export const fetchDevices = async (token) => {
  try {
    const response = await axios.get(`${URL}/max-devices`);

    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error loading text file:', error);
    throw error; // Re-throw the error to be caught by the calling function or component
  }
};

export const fetchTotalUser = async (token) => {
  try {
    const response = await axios.get(`${URL}/user-counts`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `${token}`,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    console.error('Error loading text file:', error);
    throw error; // Re-throw the error to be caught by the calling function or component
  }
};

