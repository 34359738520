import React from 'react';
import '../assets/styles/Toast.css';

const Toast = ({ message , isSuccess}) => {
    const divClassNames = `custom-toast ${isSuccess? "success": "error"}`;
    return (
        <div className={divClassNames}>
          <div className="toast-body">
            {message}
          </div>
        </div>
      );
};

export default Toast;
