import axios from 'axios';
import { URL } from './consts';

const fetchData = async (token) => {
  try {
    const response = await axios.get(`${URL}/savelivedata`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': `${token}`,
      },
    });

    const data = response.data.data;
    localStorage.setItem('data', data);

    return data;
  } catch (error) {
    console.error('Error loading text file:', error);
    throw error; // Re-throw the error to be caught by the calling function or component
  }
};

export default fetchData;
