// apiService.js
import axios from 'axios';
import { URL } from './consts';

const postData = async (data, token, setError, setSuccess) => {
  try {
    const response = await axios.post(
      `${URL}/savelivedata`,
      { data },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `${token}`,
        },
      }
    );

    const { success, message } = response.data;
    if (success) {
      setError(null);
      setSuccess(message);
      setTimeout(() => {
        setSuccess(null);
      }, 2000);
    } else {
      setError(message);
      setSuccess(null);
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  } catch (error) {
    setError('Data posting failed');
    setSuccess(null);
    setTimeout(() => {
      setError(null);
    }, 2000);
  }
};

export default postData;
